.game-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    
  }
  font-family {
    margin: 0;
    font-family: "Times New Roman", Times, serif;
  
  }
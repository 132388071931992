/* Card.css */
.card-container {
  display: flex;
  justify-content: space-around;
}

.card-feature
{
  display: flex;
  width: 100px;
  height: 100px;
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 300px;
  border: 1px solid #ddd;
  padding: 16px;
  margin: 16px;
  border-radius: 8px;
  transition: transform 0.3s ease
}

.card:hover {
  transform: scale(1.1);
  /* Scale the card on hover */
}

h2 {
  margin-bottom: 8px;
}



.img-feature {
  width: auto;
  height: 75px;
  object-fit: cover;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway-Regular.ttf') format('truetype');
  /* Replace with the correct path to your Raleway font file */
}

@font-face {
  font-family: 'Raleway-bold';
  src: url('../fonts/Raleway-Bold.ttf') format('truetype');
  /* Replace with the correct path to your Raleway font file */
}

@font-face {
  font-family: 'Raleway-ExtraBold';
  src: url('../fonts/Raleway-ExtraBold.ttf') format('truetype');
  /* Replace with the correct path to your Raleway font file */
}


.body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.family-font {
  font-family: "Raleway", "Times New Roman", Times, serif;
}

.button-spacing {
  margin-right: 10px;
}

.large-text {
  font-size: 25px;
}

.big-text {
  font-size: 40px;
}

.medium-text {
  font-size: 20px;
}

.small-text {
  font-size: 14px;
}

.h1 {
  font-size: 32px;
  color: #333;
  text-align: center;
}

.text-color {
  color: #333;
}

.button {
  background-color: rgba(0, 123, 255, 0.8);
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

.left-align {
  text-align: left;
}

* {
  box-sizing: border-box;
}

.container {
  margin: 1rem;
  text-align: left;
}

.nav-menu {
  background-color: #E1EBEE;
  color: #20222e;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
  height: 50px;
}

.site-title {
  font-size: 20px;
  font-weight: bold;
}

.nav-menu ul {
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
  font-size: large;
  font-weight: bold;

}

.nav-menu a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.nav-menu li.active {
  background-color: rgba(85, 85, 85, 0.2);
}

.nav-menu li:hover {
  background-color: rgba(119, 119, 119, 0.2);
}


.center-div {
  display: flex;
  justify-content: center;
  align-items: center;


}

.logs-div {
  background-color: #f2f2f2;
  font-size: 32px;

}

/* Style the login form */
.login-form {
  width: 400px;
  height: auto;
  color: #000;
  font-family: 'roboto', sans-serif;
}


input::placeholder {
  color: #000;
}



input:focus::placeholder {
  color: #000;
}


.center-form-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh;
}

.form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.form-group label {
  margin-right: 10px;
  /* Decrease the left margin */
  margin-left: 20px;
}

.centered-form {
  display: flex;
  justify-content: center;
  align-items: center;

}

.bug-text {
  font-size: 22px;
  color: rgba(255, 54, 43, 0.8);
  font-weight: bold;
}

.featuredin {
  width: 60%;
  height: 150px;
  object-fit: cover;

}

.p-custom-text {
  font-size: 22px;

}

.navbar-brand.font-style {
  font-size: 32px;
  font-weight: bold;
  font-family: 'Proxima Nova';
}


.button-container .btn.orange-color {
  background-color: #FF6633;
  color: white;
}

.btn.orange-color {
  background-color: #FF6633;
  font-family: 'Proxima Nova';
  font-weight: bold;
  color: white;
}

.btn.orange-color:hover {
  background-color: #FF6633;
}

.form-control.circule {
  border-radius: 50px;
}

.btn.circule {
  border-radius: 50px;
}

.orange-color-font {
  color: #FF6633;
}

.orange-background-color {
  color: #FF6633;
}
.orange-background-color-text-white {
  background-color: #FF6633;
  color: white;
}


.nav-link-background-orange.active {
  background-color: #FF6633 !important;
}

.nav-link-submenu {
  margin-left: 15px;
}

.nav-link-submenu2 {
  margin-left: 40px;
}

.dark-color-font {
  color: #272F3A;
  font-weight: 900;
}

.button-container .btn.black-color {
  background-color: #272F3A;
  color: white;
}

.navbar-nav .nav-link {
  margin-right: 32px;
  font-size: 18px;
  color: #000;
}

.navbar-links-col {
  margin-bottom: 0;
}

.custom-hr {
  margin-top: 0;
  color: black;
  border-color: black;
}

.main-text {
  font-size: 60px;
  font-weight: bold;
  font-family: 'Raleway';
}

.normal-text {
  font-size: 26px;
  font-family: 'Raleway';
}


.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
}

.dark-back-ground-color {
  color: #272F3A;
}

.link-container a {
  font-size: small;
  text-align: left;
  font-family: 'Raleway';
  font-weight: bold;
}

.small-image {
  width: 40px;
  height: auto;
}

.font-family-Raleway {
  font-family: 'Raleway', sans-serif;
}

.font-family-RalewayBold {
  font-family: 'Raleway-Bold', sans-serif;

}

.font-family-ProximaNova {
  font-family: 'Proxima Nova', sans-serif;

}

.font-family-RalewayExtraBold {
  font-family: 'Raleway-ExtraBold', sans-serif;

}



.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrapper {
  flex: 1;
}

form {
  font-family: 'Proxima Nova', sans-serif;
}

.a-nostyle {
  text-decoration: none;
  color: inherit;
  padding: 6.5px;
  display: inline-block;
}

.dark-back-ground-color {
  background-color: #20222E;
  padding: 0;
}

.card.dark-card {
  background-color: #20222E;
  color: #fff;
}

.centered-text {
  text-align: center;
  font-size: 20px;
}

.card-wrapper {
  position: relative;
  display: inline-block;
}

.top-left-text {
  position: absolute;
  top: 0;
  left: 5;
  padding: 10px;
  background: white;
  z-index: 2;
}

.footer {
  background-color: #20222E;
  padding: 20px 0;
  text-align: center;
  bottom: 0;
  margin-top: auto;
}

.link-container .social-icons a {
  display: inline-block;
}

.video-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-frame {
  width: 100%;
  max-width: 800px;
}

.video-frame video {
  width: 100%;
  height: auto;
}

.alert-orange {
  background-color: #FF6633;
  padding: 15px;
  color: white;
  border-radius: 8px;
}

.green-tick-list {
  list-style-type: none;
}

.green-tick-list li {
  position: relative;
  padding-left: 30px;
}

.green-tick-list li::before {
  content: '\2713';
  display: block;
  position: absolute;
  left: 0;
  font-size: 1.5em;
  color: green;
}




.print-icon {
  cursor: pointer;
  font-size: 30px;
  color: #FF6633;
}

.grid-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.grid-list li {
  background: #f0f0f0;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
}

/* FloatingComponent.css */
.floating-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.floating-button {
  background-color: #FF6633;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.floating-button:hover {
  background-color: #FF6633;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Ensure the icon color remains white */
.floating-button-icon {
  color: #fff;
}

/* Container for the items */
.items-container {
  position: fixed;
  bottom: 100px;
  /* Position the container above the floating button */
  right: 20px;
  /* Align with the button */
  z-index: 999;
  /* Ensure items are below the button */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  /* Space between the items */
}

/* Style for each item */
.item {
  background-color: #FF6633;
  /* Button color */
  color: #fff;
  /* Icon color */
  border-radius: 50%;
  /* Make the button circular */
  width: 50px;
  /* Width of the item */
  height: 50px;
  /* Height of the item */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* Pointer cursor on hover */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* Shadow effect */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  /* Transition effects */
  position: relative;
}

/* Ensure icon color remains white */
.item-icon {
  font-size: 24px;
  /* Icon size */
  color: #fff;
}

/* Hidden text */
.item-text {
  position: absolute;
  bottom: 60px;
  /* Position text above the button */
  right: 0;
  background-color: #FF6633;
  /* Text background color */
  color: #fff;
  /* Text color */
  padding: 5px 10px;
  /* Padding */
  border-radius: 5px;
  /* Rounded corners */
  opacity: 0;
  /* Initially hidden */
  visibility: hidden;
  /* Initially hidden */
  white-space: nowrap;
  /* Prevent text wrapping */
  font-size: 14px;
  /* Text size */
}

/* Show text on hover */
.item:hover .item-text {
  opacity: 1;
  visibility: visible;
}

/* Item hover effect */
.item:hover {
  background-color: #FF6633;
  /* Darker color on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  /* Darker shadow on hover */
}


.leaflet-control-container .leaflet-routing-container-hide {
  display: none;
}

.container-map-modal {
  display: flex;
  justify-content: space-between;
}

.left,
.right {
  display: flex;
  flex-direction: column;
}
@keyframes moveLeftToRight {
  0% {
    transform: translateX(0); /* Start from the left */
  }
  50% {
    transform: translateX(50vw); /* Move halfway across the screen (50% width of viewport) */
  }
  100% {
    transform: translateX(0); /* Reset back to the left, creating a looping effect */
  }
}

.custom-marker-label {
  background: rgba(0, 0, 0, 0.7); /* Optional: Background color */
  padding: 5px 10px; /* Add some padding to adjust the width */
  border-radius: 4px; /* Optional: Rounded corners */
  width: 100px; /* Set a specific width */
  text-align: center; /* Center align the text */
}
